import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'instruções',
  templateUrl: 'instructions.component.html',
  styleUrls: ['./instructions.component.css'],
})

export class InstructionsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InstructionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() { }

}
