import { Injectable } from '@angular/core';

import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ClienteService extends BaseService {

  public getForms(): Observable<any> {
    return this.http.get(this.baseUrl + '/api/v2/admin/asap-user/form', { headers: this.getHeader() });
  }

  public getAdressByCep(cep: string): Observable<any> {
    const headerOptions = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return this.http.get('http://viacep.com.br/ws/' + cep + '/json/', { headers: new HttpHeaders(headerOptions) });
  }

  public getClienteData(idCliente: number = null): Observable<any> {
    if (idCliente !== null) {
      return this.http.get(this.baseUrl + '/api/v2/admin/asap-client/' + idCliente, { headers: this.getHeader() });
    }
    return this.http.get(this.baseUrl + '/api/v2/admin/asap-client', { headers: this.getHeader() });
  }

  getColorByHost(host): Observable<any> {
    let data = {
      url: host
    }
    return this.http.post(this.baseUrl + '/api/v2/admin/color/host', data, { headers: this.getHeader() });
  }

  public saveData(data: any, idCliente = null): Observable<any> {
    const headerOptions = {
      'Authorization': localStorage.getItem('token'),
      'Accept': 'application/json'
    };
    if (idCliente === null) {
      return this.http.post(this.baseUrl + '/api/v2/admin/asap-client/store',
        data,
        { headers: new HttpHeaders(headerOptions) });
    }
    return this.http.put(this.baseUrl + '/api/v2/admin/asap-client/' + idCliente + '/update',
      data,
      { headers: new HttpHeaders(headerOptions) });
  }
}
