export class Comment {
  public feed_itens_id: number;
  public comment: string;

  constructor() {
  }

  populate(data): Comment {
    this.feed_itens_id = data.feed_itens_id;
    this.comment = data.comment;
    return this;
  }
}
