import { FilenamePipe } from './filename.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SafeUrlPipe } from './safe-url.pipe';
import { AsapClientPipe } from './asap-client.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    SafeUrlPipe,
    AsapClientPipe,
    FilenamePipe
  ],
  exports: [
    SafeUrlPipe,
    AsapClientPipe,
    FilenamePipe
  ]
})

export class PipesModule { }
