import { Component, OnInit, ElementRef } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../models/user';
import { MatDialog } from '@angular/material/dialog';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-login-cmp',
  templateUrl: './login.component.html',
  providers: [AuthService],
})

export class LoginComponent implements OnInit {

  public user: User;
  public form: FormGroup;

  constructor(
    public authService: AuthService,
    public router: Router,
    public fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
  }

  buildForm(): void {
    this.form = this.fb.group({
      username: ['', Validators.minLength(2)],
      password: ['', Validators.minLength(2)]
    });
  }

  onSubmit(): void {
    this.user = this.prepareLogin();
    this.authService.login(this.user)
      .subscribe(data => {
        this.authService.setSessionToken(data)
          .then(res => {
            if (res) {
              this.redirect();
            } else {
              this.router.navigate(['/erros/403']);
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
        err => {
          console.log(err);
          this.errorNotificate('Nome de usuário ou senha incorretos.');
        });
  }

  redirect(): void {
    this.router.navigate(['/dashboard']);
  }

  prepareLogin(): User {
    const formModel = this.form.value;
    const userModel = new User();
    userModel.populate(formModel);
    return userModel;
  }

  errorNotificate(msg: string): any {
    swal({
      title: 'Erro',
      text: msg,
      type: 'warning',
      confirmButtonClass: 'btn btn-success',
    });
  }

  getRouter(): Router {
    return this.router;
  }
}
