import { Routes } from '@angular/router';

import { ForbiddenComponent } from './modules/shared/pages/forbidden.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'portal',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./edital-abertura/auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    loadChildren: () => import('./edital-abertura/auth/register/register.module').then(m =>  m.RegisterModule)
  },
  {
    path: '',
    loadChildren: () => import('./portal/portal.module').then(m =>  m.PortalModule)
  },
  {
    path: '',
    loadChildren: () => import('./public/public.module').then(m =>  m.PublicModule)
  },
  {
    path: '',
    loadChildren: () => import('./edital-abertura/edital-abertura.module').then(m => m.EditalAberturaModule)
  },
  {
    path: 'acompanhamento',
    loadChildren: () => import('./edital-acompanhamento/edital-acompanhamento.module').then(m => m.EditalAcompanhamentoModule)
  },
  {
    path: 'erros', children: [
      { path: '403', component: ForbiddenComponent }
    ]
  }
];
