import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Feed } from '../models/feed';
import { Answer } from '../models/answer';
import { Comment } from '../models/comment';
import { environment } from '../../environments/environment';
import * as Pusher from 'pusher-js';
import { BaseService } from './base.service';

@Injectable()
export class FeedService extends BaseService {

  pusher: any;
  feedChannel: any;

  constructor(public http: HttpClient) {
    super(http);
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: 'eu',
      encrypted: true
    });
    this.feedChannel = this.pusher.subscribe('my-channel');
  }

  getFeed(page: number): Observable<any> {
    const token = localStorage.getItem('token');

    const headerOptions = {
      'Authorization': token,
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    };

    const header = new HttpHeaders(headerOptions);

    return this.http.get(this.baseUrl + '/api/v2/client/feed?page=' + page, { headers: header });
  }

  getFeedUser(): Observable<any> {
    const token = localStorage.getItem('token');

    const headerOptions = {
      'Authorization': token,
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    };

    const header = new HttpHeaders(headerOptions);

    return this.http.get(this.baseUrl + '/api/v2/client/feed/user', { headers: header });
  }

  getFeedVisitor(id): Observable<any> {
    const token = localStorage.getItem('token');

    const headerOptions = {
      'Authorization': token,
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    };

    const header = new HttpHeaders(headerOptions);

    return this.http.get(this.baseUrl + '/api/v2/client/feed/user/search/' + id, { headers: header });
  }

  getCategories(): Observable<any> {
    const token = localStorage.getItem('token');
    if (token) {
      const headerOptions = {
        'Authorization': token,
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      };
      const header = new HttpHeaders(headerOptions);
      return this.http.get(this.baseUrl + '/api/v2/client/feed/pasta', { headers: header });
    }
  }

  getFeedByFolder(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/api/v2/client/feed/pasta/' + id, { headers: this.getHeader() });
  }

  publishNewPost(data: Feed): Observable<any> {
    const url = this.baseUrl + '/api/v2/client/feed/pasta/item/post';
    const post = {
      'pasta_id': data.pasta_id,
      'text': data.texto,
      'title': data.titulo,
      'embed': data.embed,
      'embedTipo': data.embedTipo,
      'image': data.image,
      'author': data.author
    };
    const token = localStorage.getItem('token');
    const headerOptions = {
      'Authorization': token,
      'Accept': 'application/json'
    };

    return this.http.post(url, post, { headers: headerOptions });
  }

  publishUpdatePost(data): Observable<any> {
    const url = this.baseUrl + '/api/v2/client/feed/pasta/item/' + data.id + '/update';
    const post = {
      'id': data.id,
      'text': data.texto,
      'pasta_id': data.pasta_id,
      'title': data.title,
      'embed': data.embed,
      'embedTipo': data.embedTipo,
      'image': data.image,
      'author': data.author
    };
    const token = localStorage.getItem('token');
    const headerOptions = {
      'Authorization': token,
      'Accept': 'application/json'
    };

    return this.http.put(url, post, { headers: headerOptions });
  }

  deleteFeed(id): Observable<any> {
    return this.http.delete(this.baseUrl + '/api/v2/client/feed/pasta/item/' + id + '/delete', { headers: this.getHeader() })
  }

  updateComment(data): Observable<any> {
    return this.http.put(this.baseUrl + '/api/v2/client/feed/comment/' + data.id + '/update',
      data, { headers: this.getHeader() })
  }

  deleteComment(id): Observable<any> {
    return this.http.delete(this.baseUrl + '/api/v2/client/feed/comment/' + id + '/delete', { headers: this.getHeader() })
  }

  updateAnswer(data): Observable<any> {
    console.log(data.id)
    return this.http.put(this.baseUrl + '/api/v2/client/feed/answer/' + data.id + '/update',
      data, { headers: this.getHeader() })
  }

  deleteAnswer(id): Observable<any> {
    return this.http.delete(this.baseUrl + '/api/v2/client/feed/answer/' + id + '/delete', { headers: this.getHeader() })
  }

  publishNewComment(data: Comment): Observable<any> {
    return this.http.post(
      this.baseUrl + '/api/v2/client/feed/comment/post?feed_itens_id='
      + data.feed_itens_id + '&comment='
      + data.comment,
      {},
      { headers: this.getHeader() }
    );
  }

  publishNewAnswer(data: Answer): Observable<any> {
    return this.http.post(
      this.baseUrl + '/api/v2/client/feed/answer/post',
      data,
      { headers: this.getHeader() }
    );
  }

  like(id: number): Observable<any> {
    const url = this.baseUrl + '/api/v2/client/feed/pasta/item/' + id + '/like';
    const header = { headers: this.getHeader() };
    return this.http.post(url, {}, header);
  }

  getHeader(): any {
    const token = localStorage.getItem('token');
    if (token) {
      const headerOptions = {
        'Authorization': token,
        'Accept': 'application/json'
      };
      return new HttpHeaders(headerOptions);
    }
  }

  getFormUrlEncoded(toConvert) {
    const formBody = [];
    for (const property of toConvert) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(toConvert[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }
}
