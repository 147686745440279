import { Component } from '@angular/core';

@Component({
    selector: 'app-portal-navbar',
    templateUrl: './portal-navbar.component.html',
    styleUrls: ['./portal-navbar.component.scss']
})

export class PortalNavbarComponent {

}
