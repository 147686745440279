
import {first, map} from 'rxjs/operators';
import { Injectable, OnInit } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';




import { Observable } from 'rxjs';

declare var swal: any;

@Injectable()
export class AuthGuardService implements CanActivate, OnInit {

  constructor(public auth: AuthService, public router: Router) {
  }

  ngOnInit(): void {
    console.log('iniciei');
  }

  canActivate(): Observable<boolean>|boolean {
    const clientId = localStorage.getItem('client');
    const token = localStorage.getItem('token');
    console.log("[A]");
    if (clientId && token) {
      return this.auth.verifyClient(+clientId).pipe(
      map(res => {
        console.log("[B]");
        if (res.success) {
          console.log("[C]");
          return true;
        } else {
          console.log("[D]");
          this.router.navigate(['/erros/403']);
          return false;
        }
      }),first(),);
    }
    else {
      console.log("[E]", this.router.url);
      if (!this.router.url.includes('\login')) {
        this.router.navigate(['/erros/403']);
      }
    }
    return false;
  }

  errorNotificate(msg: string): any {
    swal({
      title: 'Erro',
      text: msg,
      type: 'warning',
      confirmButtonClass: 'btn btn-success',
    });
  }
}
