import { Routes } from '@angular/router';

import { RegisterComponent } from './register/register.component';
import { LockComponent } from './lock/lock.component';
import { LoginComponent } from './login/login.component';

import { AuthGuardService } from '../../services/auth-guard.service';

export const AuthRoutes: Routes = [

  {
    path: 'login-legado',
    children: [{
      path: 'login',
      component: LoginComponent
    }, {
      path: 'lock',
      component: LockComponent
    }, {
      path: 'register',
      component: RegisterComponent
    }
    ]
  }
];
