import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NouisliderModule } from 'ng2-nouislider';
import { SpinnerComponent } from './spinner/spinner.component';
import { OwlModule } from 'ngx-owl-carousel';

import { PipesModule } from '../@core/pipes/pipe.module';

import { NavbarComponent } from './navbar/navbar.component';
import { PortalNavbarComponent } from './portal-navbar/portal-navbar.component';
import { PortalFooterComponent } from './portal-footer/portal-footer.component';
import { SideMenuComponent } from './sidemenu/sidemenu.component';
import { InstructionsComponent } from './instructions/instructions.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NouisliderModule,
    RouterModule,
    OwlModule,
    PipesModule
  ],
  declarations: [
    SpinnerComponent,
    NavbarComponent,
    SideMenuComponent,
    PortalNavbarComponent,
    PortalFooterComponent,
    InstructionsComponent
  ],
  exports: [
    OwlModule,
    PipesModule,
    SpinnerComponent,
    NavbarComponent,
    PortalNavbarComponent,
    PortalFooterComponent,
    InstructionsComponent
  ],
  entryComponents: [
    InstructionsComponent
  ]
})

export class SharedModule { }
