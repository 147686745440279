export class User {
  public id: number;
  public username: string;
  public password: string;
  public email: string;
  public name: string;
  public picture: string;
  public role: string;

  constructor() { }

  populate(data): User {
    this.id = data.id;
    this.username = data.username;
    this.password = data.password;
    this.email = data.email;
    this.name = data.name
    this.picture = data.picture;
    return this;
  }
}
