import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filename'})
export class FilenamePipe implements PipeTransform {
  constructor() {}

  transform(file: any): string {
    if (!file) {
      return '';
    }
    const split = file.split('archives%2F');
    if (split.length === 0) {
      return '';
    }
    split.shift();
    const ret = split.join('_');
    const split2 = ret.split('?alt=');
    return decodeURI(split2[0]);
  }
}
