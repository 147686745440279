import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asapClient'
})
export class AsapClientPipe implements PipeTransform {

  public static dictionary = {
    mainColor: '#333',
    appIntro: 'EDITAL ASAP',
    appIntroDetail: 'Nossa plataforma de editais está aberta para a sua participação e contribuição social. Nossos projetos visam promover impacto positivo para diferentes seguimentos como cultura, esporte e artes visuais.',
    openedIntro: 'Editais Abertos',
    openedDetail: 'Inscreva-se em qualquer um de nossos editais abertos. Ao clicar em "inscrições abertas"  você irá conhecer os detalhes do edital como regulamento, manual para orientações de participação e outras informações.',
    closedIntro: 'Editais Encerrados',
    closedDetail: 'Aqui você terá acesso às propostas contempladas e ao ambiente de acompanhamento.',

    //Logo Navbar
    appLogo: '../../../../assets/img/logo-navbar.png',
    appLogoNavBarAsap: '../../../../assets/img/logo-navbar-asap.png',
    appLogoNavBarClient: '../../../../assets/img/logo-navbar-client.png',

    // Logo Footer
    appLogoFooterAsap: '../../../../assets/img/logo-footer-asap.png',
    appLogoFooterClient: '../../../../assets/img/logo-footer-client.png',

    appLogoDark: '../../../../assets/img/logo-asap.png',
    appLogoBig: '../../../../assets/img/logo-navbar.png',
    clientLogo: '../../../../assets/img/logo-asap-fundo-branco.png'
  };

  public static setDictionary(dic) {
    for (const key in dic) {
      AsapClientPipe.dictionary[key] = dic[key];
    }
  }

  transform(value: any, args?: any): any {
    if (AsapClientPipe.dictionary[value]) {
      return AsapClientPipe.dictionary[value];
    }
    return value;
  }

}
