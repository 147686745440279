import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { RouterModule, PreloadAllModules } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ngx-currency-mask/src/currency-mask.config';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { AuthLayoutComponent } from './modules/layouts/auth/auth-layout.component';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { ForbiddenComponent } from './modules/shared/pages/forbidden.component';

import { AppRoutes } from './app.routing';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { RoleGuardService } from './services/role-guard.service';
import { ClientInterceptor } from './@core/interceptors/client-interceptor';
import { SmartfitPropostaService } from './services/smartfit-proposta.service';
import { GlobalService } from './services/global.service';



import { environment } from 'environments/environment';
import { AuthModule } from './modules/auth/auth.module';
import { FormModalComponent } from './modules/editor-monitora/components/form/form-modal/form-modal/form-modal.component';

registerLocaleData(localePt);

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left', allowNegative: false, allowZero: true, decimal: ',',
  precision: 2, prefix: 'R$ ', suffix: '', thousands: '.'
};

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules }),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    CurrencyMaskModule,
    AuthModule,
  ],
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    ForbiddenComponent
  ],
  exports: [
    SharedModule
  ],
  entryComponents: [
    
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthGuardService,
    RoleGuardService,
    AuthService,
    SmartfitPropostaService,
    GlobalService,
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: CustomCurrencyMaskConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    }

  ]
})

export class AppModule {
  constructor() {
    console.log('Iniciando o AppModule', new Date());
    // matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
    // Or whatever path you placed mdi.svg at
  }
}
