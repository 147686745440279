import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../../services/auth.service';
import { User } from '../../../models/user';

declare var swal: any;

@Component({
  selector: 'app-register-cmp',
  templateUrl: './register.component.html',
  providers: [AuthService]
})

export class RegisterComponent implements OnInit {

  test: Date = new Date();

  public isLoading: boolean;

  public client_id: number;
  public register: User;

  constructor(
    public authService: AuthService,
    public router: Router
  ) {
    this.isLoading = false;

    this.register = new User;
    this.register.picture = "";
    this.register.role = "user";
  }

  ngOnInit() {
  }

  onSubmit() {
    this.isLoading = true;
    this.authService.register(this.register, this.client_id)
      .subscribe(res => {
        this.login();
      }, err => {
        this.isLoading = false;
        this.notificate();
      });
  }

  login() {
    let login = new User;
    login.username = this.register.email;
    login.password = this.register.password;

    this.authService.login(login)
      .subscribe(data => {
        this.authService.setSessionToken(data);
        this.redirect();
      },
        err => {
          this.notificate();
        });
  }

  redirect(): void {
    this.router.navigate(['/painel']);
  }

  notificate(): void {
    swal({
      title: 'Ops...',
      text: 'Erro ao registrar-se. Tente novamente!',
      type: 'danger',
      confirmButtonClass: 'btn btn-success',
    });
  }
}
