import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';




import { User } from '../models/user';
import { BaseService } from './base.service';
import { LibraryHelper } from '../@core/helpers/library.helper';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService extends BaseService {

  login(user: User): any {
    const body = {
      email: user.username,
      password: user.password
    };
    return this.http.post(this.baseUrl + '/api/oauth/access_token', body);
  }

  register(user: User, clientId: number): Observable<any> {
    const body = {
      client_id: clientId,
      user
    };
    const url = this.baseUrl + '/api/v2/client/register';
    return this.http.post(url, body);
  }

  isAuthenticated(): boolean {
    const token: string = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    return true;
  }

  setSessionToken(data): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const token = 'Bearer' + ' ' + data.token;
      const id = data.id;
      localStorage.setItem('token', token);
      this.getUserLogged().subscribe(res => {
        console.log("[setSessionToken]", res);
        this.baseUrl = res.id 
        if (res.success === false) {
          resolve(false);
        } else {
          localStorage.setItem('loggedUser', JSON.stringify(res));
          resolve(true);
        }
      }, err => reject(err));
    });
  }

  verifica(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/verifica', LibraryHelper.getHeaders());
  }

  complement(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/user/form', LibraryHelper.getHeaders());
  }

  complementSave(id: number, data: any): Observable<any> {
    return this.http.put(environment.baseUrl + '/api/v2/perfil/sobre/' + id, data, LibraryHelper.getHeaders());
  }

  getUserLogged(): Observable<any> {
    const {id} = JSON.parse(localStorage.getItem('selectedNotice'));
    const url = `${this.baseUrl}/api/v2/client/user/authenticated?id=${id}`;
    return this.http.get(url, LibraryHelper.getHeaders());
  }
  

  verifyClient(clientId: number): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/client/verifyclient', { client_id: clientId }, LibraryHelper.getHeaders());
  }

  userInfo(id): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/feed/userInfo/' + id, LibraryHelper.getHeaders());
  }

  dismissStorage(): void {
    localStorage.clear();
  }

  syncActiveClient(client: string): void {
    console.log('ClientID Sync', client);
    localStorage.setItem('client', client);
  }

  getActiveClient(): any {
    console.log('getActiveClient');
    if (localStorage.getItem('client')) {
      return +localStorage.getItem('client');
    }

    return null;
  }
}
