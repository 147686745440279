import {Component, OnInit} from '@angular/core';
import {FeedService} from '../../services/feed.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Feed} from '../../models/feed';
import {Comment} from '../../models/comment';
import {AuthService} from '../../services/auth.service';

declare var $: any;

@Component({
  selector: 'app-clientes-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
  providers: [FeedService, AuthService]
})

export class SpinnerComponent implements OnInit {

  private arrayIdsCategories: any = [];
  public arrayFeedList: any = [];
  public form: FormGroup;
  public formComment: FormGroup;
  private feed: Feed;
  private loggedInUser: any;

  constructor(private feedService: FeedService, public fb: FormBuilder, private authService: AuthService) {
    this.getCategories();
    this.buildForm();
  }

  getUsuarioLogado(): any {
    const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
    this.loggedInUser = loggedUser.data;
  }

  ngOnInit() {
    this.getUsuarioLogado();
  }

  buildForm(): void {
    this.form = this.fb.group({
      texto: ['', Validators.minLength(5)],
    });
    this.formComment = this.fb.group({
      comment: ['', Validators.minLength(5)],
    });
  }

  onSubmit(): any {
    this.feedService.publishNewPost(this.preparePostItem())
      .subscribe(res => {
        // TODO Gato para solucionar enquanto o Higor não faz o socket
        $(location).attr('href', '/fitness-link/painel');
      });
  }

  onSubmitComment(id: number): any {
    this.feedService.publishNewComment(this.prepareComment(id))
      .subscribe(res => {
        // TODO Gato para solucionar enquanto o Higor não faz o socket
        $(location).attr('href', '/fitness-link/painel');
      });
  }

  preparePostItem(): Feed {
    const formModel = this.form.value;
    const feed = new Feed();
    const embed = this.checkIfHasEmbededVideo(formModel.texto);
    feed.pasta_id = 3;
    feed.titulo = formModel.texto;
    feed.texto = formModel.texto;
    feed.author = this.loggedInUser.name;

    if (typeof embed === 'object') {
      feed.embed = embed.url;
      feed.texto = '';
      feed.titulo = 'Embed';
    } else {
      feed.embed = '';
    }

    return feed;
  }

  prepareComment(id: number): Comment {
    const formModel = this.formComment.value;
    const obj = new Comment();
    obj.comment = formModel.comment;
    obj.feed_itens_id = id;
    return obj;
  }

  checkIfHasEmbededVideo(value: string): any {
    if (value.includes('vimeo')) {
      return {url: '//player.vimeo.com/video/' + value.split('/')[3]};
    } else if (value.includes('youtube')) {
      return {url: '//www.youtube.com/embed/' + value.split('=')[1]};
    } else {
      return value;
    }
  }

  getCategories(): void {
    this.feedService.getCategories()
      .subscribe(data => {
        data.data.forEach(item => {
          this.arrayIdsCategories.push(item.id);
          this.getFeedByArrayIdCategories(item.id);
        });
      });
  }

  getFeedByArrayIdCategories(id: number): void {
    this.feedService.getFeedByFolder(id)
      .subscribe(data => {
        if (data.data.length > 0) {
          data.data.forEach(item => {
            this.arrayFeedList.push(item);
          });
        }
      });
  }
}
