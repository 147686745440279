import {Component, OnInit, ElementRef} from '@angular/core';
import {LibraryHelper} from '../../../@core/helpers/library.helper';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-login-cmp',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css'],
  providers: [AuthService]
})
export class ForbiddenComponent {
  public link: string;

  constructor(private authService: AuthService) {
    console.log('AuthService');
    if (this.authService.getActiveClient() && this.authService.getActiveClient().length > 0) {
      this.link = LibraryHelper.getClient(this.authService.getActiveClient())[0].url;
    }
  }
}
