import { Component } from '@angular/core';

@Component({
  selector: 'app-portal-footer',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.css'],
  providers: []
})

export class PortalFooterComponent {

  redirect: boolean = false;

  constructor() {
    const token = localStorage.getItem('token');
    this.redirect = token ? true : false;
  }
}
