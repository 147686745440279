import { Component, OnInit } from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['../../app.component.css', './sidemenu.component.css'],
  providers: []
})

export class SideMenuComponent implements OnInit {

  public isTutor=false;
  public areas=[];

  constructor() { }

  ngOnInit() {
    $(document).ready(function() {
      $('#sidemenuCollapse').on('click', function() {
        $('.sidemenu, .content, .overlay').toggleClass('active');
      });
    
      $('.overlay').on('click', function() {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });
    
      $('.components').on('click', function() {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });
    
      $('.navbar-brand').on('click', function() {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });
    });
  }
}
