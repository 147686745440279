export class Feed {
  public id: number;
  public pasta_id: number;
  public titulo: string;
  public author: string;
  public arquivo: string;
  public embedTipo: string;
  public embed: string;
  public texto: string;
  public image: string;
  public status: string;

  constructor() { }

  populate(data): Feed {
    this.id = data.id;
    this.pasta_id = data.pasta_id;
    this.titulo = data.titulo;
    this.author = data.author;
    this.arquivo = data.arquivo;
    this.embedTipo = data.embedTipo
    this.embed = data.embed;
    this.texto = data.texto;
    this.image = data.image;
    this.status = data.status;
    return this;
  }
}
