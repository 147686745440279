import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalService } from 'app/services/global.service';

declare var $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['../../app.component.css', './navbar.component.scss'],
    providers: []
})

export class NavbarComponent implements OnChanges {

    @Input() orientation;
    @Input() singlePage;

    fontSize = 16;
classList: any;

    constructor(
        public global: GlobalService
    ) { }


    ngOnChanges(changes: SimpleChanges) {

    }

    public scrollTo(id) {
        if (id == "inscricao") {
            $("html, body").animate({ scrollTop: 0 }, 1000);
        }
        else {
            let top = $(id).offset().top;
            let navH = $("#navigation .navbar").height();
            $("html, body").animate({ scrollTop: (top - navH) }, 1000);
        }
    }


    setFontSize(idd: string) {
        if (idd === 'a+') {
            this.fontSize = this.fontSize * 1.1;
        } else if (idd === 'a-') {
            this.fontSize = this.fontSize - 1;
        } else {
            this.fontSize = 16;
        }

        let htmlRoot: HTMLElement = <HTMLElement>document.getElementsByTagName("html")[0];

        if (htmlRoot != null) {
            htmlRoot.style.fontSize = `${this.fontSize}px`;
        }
    }


    ngOnInit(): void {
    }



}
