import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()

export class ClientInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clientId = localStorage.getItem('client');
        //console.log("ClientID",clientId);
        //console.log("Request:",req);
        const changeReq = req.clone({
            params: req.params.append('client_id', clientId)
        });
        return next.handle(changeReq);
    }

}
